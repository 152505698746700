<template>
    <section class="pt-4">
        <div class="d-flex">
            <lista-geocercas ref="VerGrupos" @loadGeocercas="loadGeocercas" @reload="reloadGeocercas" />
            <div class="position-relative" style="width:calc(100vw - 489px)">
                <mapa-geocercas ref="mapaGeocercas" />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    components: {
        listaGeocercas: () => import('./components/verGrupos'),
        mapaGeocercas: () => import('./components/mapaGeocercas'),
    },
    methods:{
        loadGeocercas(){
            this.$refs.mapaGeocercas.drawGeocercas()
        },
        reloadGeocercas(){
            this.$refs.mapaGeocercas.initMapa()
            this.$refs.mapaGeocercas.drawGeocercas()
        }
    }
}
</script>

