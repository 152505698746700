import axios from 'axios'

const API = 'geocercas'

const Geocerca = {
    getGeocercasCarpetas(){
        return axios.get(`${API}/carpeta-listar`)
    },
    postGeocerca(payload){
        return axios.post(`${API}/guardar`,payload)
    },
    getGeocercas(params){
        return axios.get(`${API}/listar`,{params})
    },
    verGeocercaCarpeta(params){
        return axios.get(`${API}/carpeta-ver`,{params})
    },
    putGeocercaCarpeta(idGrupo,payload){
        return axios.put(`${API}/editar/${idGrupo}`,payload)
    },
    deleteGeocercaCarpeta(idGrupo){
        return axios.delete(`${API}/eliminar/${idGrupo}`)
    },
    postGrupoLeechero(payload){
        return axios.post(`${API}/grupo/crear`,payload)
    },
    getGrupoGeocerca(){
        return axios.get(`${API}/grupo/listar`)
    },
    putGrupoGeocercas(idGrupo,payload){
        return axios.put(`${API}/grupo/${idGrupo}`,payload)
    },
    deleteGrupoGeocerca(idGrupo){
        return axios.delete(`${API}/grupo/eliminar/${idGrupo}`)
    },
    selectCarpetaGeocerca(){
        return axios.get(`${API}/carpeta`)
    },
    selectGeocercas(params){
        return axios.get(`${API}`,{params})
    },
    asignarGeocercaLeechero(payload){
        return axios.post(`${API}/asignar/leechero`,payload)
    },
    getGeocercaGrupoGeocerca(params){
        return axios.get(`${API}/grupo/geocerca`,{params})
    },
    eliminarGeocercaGrupo(idGeocercaGrupo){
        return axios.delete(`${API}/grupo/geocerca/eliminar/${idGeocercaGrupo}`)
    },
    selectLeechero(params){
        return axios.get(`${API}/select/leecheros`,{params})
    },
    asignarGeocercaTienda(payload){
        return axios.post(`${API}/asignar/tienda`,payload)
    },
    getGeocercaGrupoTienda(params){
        return axios.get(`${API}/grupo/tienda`,{params})
    },
    asignarPrincipal(payload){
        return axios.post(`${API}/asignar/principal`,payload)
    },
    eliminarGeocercaGrupoTienda(idGrupoTienda){
        return axios.delete(`${API}/grupo/tienda/eliminar/${idGrupoTienda}`)
    },
    horariosTendero(params){
        return axios.get(`${API}/tienda/horarios`,{params})
    },
    actualizarPosicion(payload){
        return axios.put(`${API}/actualizar-posicion`,payload)
    },

}

export default Geocerca
