<template>
    <section class="pt-4">
        <div class="d-flex">
            <div class="column-group border-right">
                <div class="row mx-0 align-items-center border-bottom py-3">
                    <p class="col-auto text-general f-600 f-18">
                        Grupo de {{ $config.vendedor }}
                    </p>
                    <div v-if="$can('gestionar_asignacion_geocercas')" class="btn-red ml-auto mr-2" @click="crearGrupo">
                        <i class="icon-plus" />
                    </div>
                </div>
                <div class="pt-3" style="height:calc(100vh - 230px)">
                    <listar-leecheros :grupos="grupoGeocerca" @update="cargarVista" @setGrupo="setGrupo" @volver="componentName = 'verLeecGeocercas'" />
                </div>
            </div>
            <div class="column-group">
                <div v-if="idGrupo != 0" class="row mx-0 align-items-center border-bottom py-3 mb-2">
                    <p class="col-auto text-general f-600 f-18">
                        {{ $config.vendedor }} y geocercas
                    </p>
                    <div class="col ml-auto">
                        <el-select v-model="asignacion" class="w-100" size="small" @change="componentName = asignacion">
                            <el-option
                            v-for="item in asignaciones"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
                <div v-if="idGrupo != 0" style="height:calc(100vh - 230px)">
                    <component :is="componentName" :id-grupo="idGrupo" @cargar="cargarVista" @volver="componentName = 'verLeecGeocercas'" @horarios="horarios" />
                </div>
            </div>
            <div class="position-relative border-left" style="width:calc(100vw - 869px);height:calc(100vh - 158px)">
                <!-- Pestana -->
                <div v-if="horariosVer" class="content-horarios z-1">
                    <div class="row mx-0 border-bottom align-items-center mb-3" style="height:65px;">
                        <div class="col-auto px-3 f-16 text-general f-600">
                            Horario
                        </div>
                        <i class="icon-cancel f-17 text-general ml-auto cr-pointer mr-4" @click="horarios" />
                    </div>
                    <div class="custom-scroll overflow-auto pl-4" style="height:calc(100% - 109px)">
                        <div class="row mx-0 mb-3 pl-3">
                            <img :src="tendero.imagen" class="rounded-circle border obj-cover" width="42" height="42" />
                            <div class="col px-2">
                                <p class="text-general f-16">
                                    {{ tendero.nombre }}
                                </p>
                                <p>
                                    <i class="icon-cedis f-16 text-general" />
                                    <span class="mx-1">
                                        {{ tendero.cedis }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div v-for="(data, c) in listarHorarios" :key="c" class="row mx-0 mb-4">
                            <div class="col-auto text-general f-16">
                                {{ data.dia }}
                            </div>
                            <div class="col-auto text-general text-center f-16">
                                <div v-for="(h,k) in data.horarios" :key="k">
                                    <p>
                                        {{ h.inicio }} - {{ h.fin }}
                                    </p>
                                </div>
                                <p v-if=" data.horarios.length == 0">
                                    -
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Pestana -->
                <div class="w-100 h-100">
                    <mapaGeocercas ref="mapaGeocercas" />
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalCrearGrupo" titulo="Crear Grupo" no-aceptar adicional="Guardar" @adicional="postGrupoLeechero">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-10 my-3">
                        <small class="pl-3 text-general">
                            Nombre del grupo
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre">
                            <el-input v-model="nombre" placeholder="Nombre" maxlength="60" show-word-limit size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-10">
                        <small class="pl-3 text-general">
                            Descripción
                        </small>
                        <el-input v-model="descripcion" placeholder="Descripción" maxlength="60" show-word-limit type="textarea" :rows="5" class="w-100" size="small" />
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>

<script>
import Geocerca from '~/services/geocerca/geocerca'
import GeocercasPuntos from '~/services/geocerca/geocercasPuntos'
export default {
    components: {
        listarLeecheros: () => import('./components/listarLeecheros'),
        verLeecGeocercas: () => import('./components/verLeecherosGeocercas'),
        geocercas: () => import('./components/asigGeoLeechero'),
        leecheros: () => import('./components/asigLeechero'),
        mapaGeocercas: () => import('./components/mapaGeocercas')

    },
    data(){
        return {
            componentName: 'verLeecGeocercas',
            horariosVer: false,
            nombre: '',
            descripcion: '',
            asignaciones: [{
                value: 'leecheros',
                label: ''
            }, {
                value: 'geocercas',
                label: 'Geocercas'
            }],
            asignacion: '',
            grupoGeocerca: [],
            idGrupo: 0,
            tendero: {},
            listarHorarios: [],
        }
    },
    mounted(){
        this.getGrupoGeocerca()
		this.asignaciones[0].label = `${ this.$config.vendedor }s`
    },
    methods: {
        crearGrupo(){
            this.$refs.modalCrearGrupo.toggle();
        },
        async postGrupoLeechero(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                const payload = {
                    nombre: this.nombre,
                    descripcion: this.descripcion
                }
                await Geocerca.postGrupoLeechero(payload)

                this.notificacion('','Grupo geocerca guardado correctamente','success')
                this.$refs.modalCrearGrupo.toggle();
                this.getGrupoGeocerca();
            } catch (e){
                this.error_catch(e)
            }
        },
        async getGrupoGeocerca(){
            try {
                const {data} = await Geocerca.getGrupoGeocerca()
                this.grupoGeocerca = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        cargarVista(){
            this.getGrupoGeocerca()
            this.asignacion = ''
        },
        setGrupo(idGrupo){
            this.idGrupo = idGrupo
            this.getGeocercasGrupoMapa()
        },
        async horarios(tendero){
            try {
                if (this.horariosVer == false){
                    this.horariosVer = true
                    this.tendero = tendero

                    const {data} = await Geocerca.horariosTendero({idTienda: tendero.idTienda})
                    this.listarHorarios = data?.data

                }else{
                    this.horariosVer = false
                    this.tendero = {}
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async getGeocercasGrupoMapa(){
            try {

                const {data} = await GeocercasPuntos.getGeocercasGrupoMapa(this.idGrupo)
                this.$store.commit('geocercas/geocercas/setGeocercasGrupo',data.geocercas)
                this.$refs.mapaGeocercas.initMapa()
                this.$refs.mapaGeocercas.drawGeocercasGrupo()

            } catch (error){
                this.error_catch(error)
            }
        }
    }

}
</script>
<style lang="scss" scoped>
.column-group{
    width: 380px;
    background-color: #FFFFFF;
    border-radius: 8px 0px 0px 0px;
}
.horarios{
    width: 124px;
    height: 44px;
    box-shadow: 0px 3px 6px #0000000D;
    background-color: #FFFFFF;
    border-radius: 0px 0px 8px 0px;
    left: 0px;
    top: 0px;
}
.content-horarios{
    background-color: #FFFFFF;
    width: 80%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
}

.item{
    cursor: pointer;
    &:hover{
        background-color: #F6F9FB;
    }
}
</style>
