import axios from 'axios'

const API = 'geocercas'

const Geocercas = {
    guardarGeocerca(form){
        return axios.post(`${API}`,form)
    },
    getGeocercasGrupoMapa(idGrupo){
        return axios.get(`${API}/grupo/${idGrupo}/geocercas`)
    },
    updateGeocerca(form){
        return axios.put(`${API}/${form.id}/editar`,form)
    },
    deleteGeocerca(idGeocerca){
        return axios.delete(`${API}/${idGeocerca}/delete`)
    }
}
export default Geocercas
