<template>
    <section class="pt-4">
        <div class="d-flex">
            <listarGrupos />
            <div class="position-relative" style="width:calc(100vw - 489px)">
                <mapa-geocercas />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    components: {
        listarGrupos: () => import('./components/listarGrupos'),
        mapaGeocercas: () => import('./components/mapaGeocercas'),
    },
}
</script>
<style lang="scss" scoped>
.column-group{
    width: 380px;
    background-color: #FFFFFF;
    border-radius: 8px 0px 0px 0px;
}
</style>
