<template>
    <section>
        <tabs :tabs="tabs" class="border-bottom" />
        <router-view />
    </section> 
</template>

<script>
export default {
    data(){
        return {
            tabs: [
                {
                    titulo: 'Geocercas',
                    ruta: 'admin.geocercas.grupos'
                },
                {
                    titulo: 'Asignación',
                    ruta: 'admin.geocercas.leecheros'
                }
            ]
        }
    }
}
</script>